import type { IRecipient, ITab, IDocument, ISignNodeState } from '.';

import { useCallback, useMemo } from 'react';

import { useParams, useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { API_URL, ENVELOPE_PURPOSE, RECIPIENT_TYPE, VIEW_SIGN_PROD_DATE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { APP_ROUTES, CreateSignState, GenerateSignImagesState, ICreateNewSignPayload, PreserveCreateSignAPIState, TabConfigurationState, WebComponentMemory } from 'views';
import { GetInitials, GetQueryParams, formatBase64 } from 'utils';
import { MESSAGE } from 'constant';
import { v4 } from 'uuid';
import { organizationDetailState } from 'states';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';


import {
	CompiledSigningNodesState,
	SignDocumentState,
	signingFieldsFilledState,
	docStatus,
	selectedNodeState,
	nodeRefsState,
	SignDocSelector,
	SignWebComponentDataState,
	IsFontsLoadedState,
	IAuthUserAuthType,
	IsFooterLoaderBtn,
	CurrentUserAllTabs,	
	completedRecipientsTabsState,
} from '.';
export const useSetSignDoc = (envelopeId?: string, recipientId?: string) => {
	const setConfiguration = useSetRecoilState(TabConfigurationState);
	const setSignDocState = useSetRecoilState(SignDocumentState);
	const setAuthUserAuthType = useSetRecoilState(IAuthUserAuthType);
	const setCreateSign = useSetRecoilState(CreateSignState);
	const setPreserveCreateSignAPIstate = useSetRecoilState(PreserveCreateSignAPIState)
	const setNodes = useSetRecoilState(CompiledSigningNodesState);
	const currentRecipientId = recipientId || GetQueryParams('recipient');
	const setGenerateFontFamilies = useSetRecoilState(GenerateSignImagesState);
	const [isLoaded, setIsLoaded] = useRecoilState(IsFontsLoadedState);
	const setOrganization = useSetRecoilState(organizationDetailState);
	const setCurrentUserAllTabs = useSetRecoilState(CurrentUserAllTabs);
	const setCompletedReipientsTabs = useSetRecoilState(completedRecipientsTabsState);

	const { get } = useNetwork();
	const { id } = useParams();
	const paramId = envelopeId || id;
	const navigate = useNavigate();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const completedRecipientsTabs = useCallback(
		(data: ISignNodeState) => {
			const { recipients = [], documents = [], createdAt } = data ?? {};

			const oldPackets = new Date(createdAt as string) < VIEW_SIGN_PROD_DATE;
			if (oldPackets) {
				setCompletedReipientsTabs([])
				return
			}
			
			const compiledTabs: ITab[] = [];
			(documents as IDocument[]).forEach(({ document, tabs }, docIdx) => {
				const completedRecipients = recipients.filter(
					(recipient: IRecipient) =>
						recipient.status === 'completed' ||
						recipient.status === 'underReview' ||
						recipient.status === 'approved'
				);
				const completedRecipientsIds = completedRecipients.map(
					(recipient: IRecipient) => recipient._id
				);

				const completedTabs = tabs
					.filter((tab) => {
						if (tab.source === 'common') {
                            return false;
                        }
						const associatedRecipient = recipients.find((item: IRecipient) => item._id === tab.recipientId);
						if (completedRecipientsIds.includes(tab.recipientId) || tab.recipientId === null) {
							if (tab.recipientId === null && data.status === "underReview") {
								return true;
							}
							switch (associatedRecipient?.status) {
								case "underReview":
								case "pending":	
									return tab.type !== "initial" && tab.type !== "signature";
								case "completed":
									return true;
								case "approved":
									return tab.type !== "initial" && tab.type !== "signature"
								default:
									return true
							}
						}
						return false;
					})
					.map((tab) => ({ ...tab, recipientStatus: 'completed' }));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const formattedTabs = completedTabs.map((tab: any) => {
					const { height: containerHeight, width: containerWidth } =
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						document.pages[tab.pageNumber - 1] as any;
					const { xLeft, xRight, yTop, yBottom } = tab.position;

					const elementsWidth = (xRight - xLeft) * containerWidth;
					const elementsHeight = (yBottom - yTop) * containerHeight;

					const metadata = {
						...(tab?.metadata || {}),
						width: elementsWidth,
						height: elementsHeight,
					};

					if (tab.type === 'initial' || tab.type === 'signature') {
						const signUrl = tab.signature?.signDetail?.document?.path;
						const initialUrl = tab.signature?.initialDetail?.document?.path;
						return {
							...tab,
							isShow: true,
							documentId: document._id,
							documentNumber: docIdx + 1,
							metadata,
							value: tab.type === 'initial' ? initialUrl : signUrl,
						};
					}
					if (tab.type === "hyperlink") {
						return {
							...tab,
							metadata,							
						}
					}
					if (
						tab.type === 'text' &&
						tab.metadata.readOnly === true &&
						tab.value === ''
					) {
						return {
							...tab,
							documentId: document._id,
							documentNumber: docIdx + 1,
							value: tab.metadata.editText ?? '',
							metadata,
						};
					}
					if (
						tab.type === 'checkbox' &&
						tab.metadata.readOnly === true &&
						tab.value === ''
					) {
						return {
							...tab,
							documentId: document._id,
							documentNumber: docIdx + 1,
							value: false,
							metadata,
						};
					}
					return {
						...tab,
						documentId: document._id,
						documentNumber: docIdx + 1,
						metadata,
					};
				});
				compiledTabs.push(...formattedTabs);
			});
			setCompletedReipientsTabs(compiledTabs);
		},
		[setCompletedReipientsTabs]
	);

	const fetchSignDoc = useCallback(async () => {
		setSignDocState((prev) => ({ ...prev, isLoaded: false }));
		const resp = await get(
			`${API_URL.ENVELOPE}/${paramId}?recipientId=${currentRecipientId}`
		);
		const { apiData, response } = resp;
		if (response.status === 200) {
			const { recipients, status, documents, reviewSign, purpose, configuration } = apiData?.data ?? {};
			// storing 'facial' if facial is true
			setAuthUserAuthType(apiData?.data?.authType);
			setConfiguration(configuration);
			setSignDocState((prev) => ({
				...prev,
				isLoaded: true,
				data: apiData?.data || {},
			}));
			// set white label data
			if (apiData?.data?.whiteLabelInfo?.whitelabel) {
				setOrganization(apiData?.data?.whiteLabelInfo);
			}
			if (status === docStatus[1]) {
				if (!recipientId) {
					window.location.href = `/signin-success-screen`;
				}
				return;
			}
			const currentRecipient = recipients.find(
				(recipient: IRecipient) => recipient._id === currentRecipientId
			);				
			if (currentRecipient) {
				setCreateSign((prev) => ({
					...prev,
					fullName: currentRecipient?.fullName ?? '',
					initials: GetInitials(currentRecipient.fullName),
				}));
				
				// Use this state to preserve the initial values from the API call
				setPreserveCreateSignAPIstate((prev) => ({
					...prev,
					fullName: currentRecipient?.fullName ?? '',
					initials: GetInitials(currentRecipient.fullName),
				}));
			}
			const compiledTabs: ITab[] = [];
			(documents as IDocument[]).forEach(
				(
					{
						document,
						tabs
					},
					docIdx
				) => {
					const currentUsersTab = tabs.filter((tab) => {
						// Check if currentRecipient status is 'pending' and reviewSign is true
						if (reviewSign && purpose === 'multisignAgreement') {
							// Check if tab type is not 'signature' or 'initial'
							if (currentRecipient.status === 'pending') {
								if (
									tab.type !== 'signature' &&
									tab.type !== 'initial' &&
									tab.source !== 'common'
								) {
									return tab.recipientId === currentRecipientId;
								}
							} else if (currentRecipient.status === 'approved') {
								if (tab.type === 'signature' || tab.type === 'initial') {
									return tab.recipientId === currentRecipientId;
								}
							}
						} else {
							// For other cases, check if tab recipientId matches currentRecipientId and not includes common tabs 
							return (
								tab.recipientId === currentRecipientId &&
								tab.source !== 'common'
							);
						}

						return false; // Default case
					});

					/* In case of multisignAgreement tab recipientId will be null because in common
					   field tabs we not get recipientId */
					setCurrentUserAllTabs(
						tabs.filter(
							(tab) =>
								tab.recipientId === currentRecipientId ||
								(purpose === 'multisignAgreement' && tab.recipientId === null)
						)
					);

					// In case of multisignAgreement filtering common tabs for current users
					const commonTabs = tabs.filter((tab) => tab.source === 'common');

					// get current user all tabs nodes
					const getCurrentUsersAllTabs =
						(purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT &&
							currentRecipient.status === 'pending') ||
						purpose === ENVELOPE_PURPOSE.WORKFLOW
							? commonTabs.concat(currentUsersTab)
							: currentUsersTab;

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const formattedTabs = getCurrentUsersAllTabs.map((tab: any) => {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						const {height: containerHeight, width: containerWidth} = document.pages[tab.pageNumber - 1] as any;

						const {xLeft, xRight, yTop, yBottom} = tab.position
						
						const elementsWidth = (xRight - xLeft) * containerWidth;
						const elementsHeight = (yBottom - yTop) * containerHeight;
						
						const metadata = {
							...(tab?.metadata || {}),
							width: elementsWidth,
							height: elementsHeight,
						};

						if (tab.type === 'initial' || tab.type === 'signature') {
							return {
								...tab,
								isShow: false,
								documentId: document._id,
								documentNumber: docIdx + 1,
								metadata,
							};
						}
						if (
							tab.type === 'text' &&
							tab.metadata.readOnly === true &&
							tab.value === ''
						) {
							return {
								...tab,
								documentId: document._id,
								documentNumber: docIdx + 1,
								value: tab.metadata.editText ?? '',
								metadata,
							};
						}
						if (
							tab.type === 'checkbox' &&
							tab.metadata.readOnly === true &&
							tab.value === ''
						) {
							return {
								...tab,
								documentId: document._id,
								documentNumber: docIdx + 1,
								value: false,
								metadata,
							};
						}
						return {
							...tab,
							documentId: document._id,
							documentNumber: docIdx + 1,
							metadata,
						};
					});
					compiledTabs.push(...formattedTabs);
				}
			);
			const tabs = compiledTabs.sort(
				(a: ITab, b: ITab) =>
					a.documentNumber - b.documentNumber ||
					a.pageNumber - b.pageNumber ||
					a.position.yTop - b.position.yTop ||
					a.position.xLeft - b.position.xLeft
			) as ITab[];
			setNodes(tabs);
			completedRecipientsTabs(apiData?.data);
			return apiData?.data
		} else if (response.status === 400) {
			navigate(APP_ROUTES.LINK_EXPIRED);
		} else {
			setSignDocState((prev) => ({ ...prev, isLoaded: true, error: true }));
		}			
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentRecipientId,
		get,
		navigate,
		paramId,
		recipientId,
		setAuthUserAuthType,
		setCreateSign,
		setNodes,
		setSignDocState,
	]);			

	const fetchSignFonts = useCallback(
		async (name: string, initial: string) => {
			setIsLoaded(false);
			const resp = await get(
				`${API_URL.SIGNATURE_FONT}?name=${name}&initial=${initial}`
			);
			/**
			 * logs added for production debugging
			 * */ 
			// eslint-disable-next-line no-console
			console.log("resp", resp);
			const { response, apiData } = resp;
			if (response?.status === 200) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const fonts = (apiData?.data ?? []).map((font: any) => ({
					...font,
					id: v4(),
				}));
				setGenerateFontFamilies(fonts);
				setIsLoaded(true);
				return;
			}
			setIsLoaded(true);
		},
		[get, setGenerateFontFamilies, setIsLoaded]
	);

	return { fetchSignDoc, fetchSignFonts, isLoaded };
};

export const usePublishSignedDoc = () => {
	const [isLoaded, setIsLoaded] = useRecoilState(IsFooterLoaderBtn);
	const setSigningFieldsFilled = useSetRecoilState(signingFieldsFilledState);
	const { errorNotification } = useNotification();
	const { purpose } = useRecoilValue(SignDocSelector);
	const [webComponentData, setWebComponentData] = useRecoilState(
		SignWebComponentDataState
	);
	const { userBioMetrics, recipient } = useRecoilValue(SignDocSelector);

	const { API_ERROR_MESSAGE } = MESSAGE;
	const navigate = useNavigate();
	const [searchParams]= useSearchParams();

	const { post, patch } = useNetwork();

	const redirectUrl = useMemo(() => {
		if (
			purpose === ENVELOPE_PURPOSE.WORKFLOW ||
			purpose === ENVELOPE_PURPOSE.SELFSIGN ||
			(purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT &&
				(recipient?.type === RECIPIENT_TYPE.DEFAULT ||
					recipient?.type === RECIPIENT_TYPE.ONBOARDING))
					||
					(
						purpose === ENVELOPE_PURPOSE.ACCREDITATION && !webComponentData.recipientId
					)
		) {
			return '/signin-success-screen';
		} else if (!WebComponentMemory.isWebComponent) {
			return '/signin-success-screen'
		}
		else {
			return '/back-to-simplici';
		}
	}, [purpose, recipient?.type, webComponentData?.recipientId]);

	// this will create the pdf of images and will upload at the same time.
	const uploadSignedDocument = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any) => {
			return post(`${API_URL.UPLOAD}`, payload);
		},
		[post]
	);

	/* conerting image link into base 64 */
	const imagetToBase64 = useCallback(
		async (signPath: string): Promise<string> => {
			try {
				const response = await fetch(signPath);
				const blob = await response.blob();
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = () => {
						resolve(reader.result as string);
					};
					reader.onerror = reject;
				});
			} catch (error) {
				errorNotification(API_ERROR_MESSAGE);
				throw error;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const getSize = useCallback((base64String: string) => {
		const stringLength = base64String.length - 'data:image/png;base64,'.length;
		const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
		const sizeInKb = sizeInBytes / 1000;
		return sizeInKb;
	}, []);

	/* handling new create sign id only for "externalUser" */
	const handleNewCreateSignId = useCallback(
		async (envelopeId: string) => {
			const { initial, signature } = userBioMetrics;
			const { document: initialDocument } = initial ?? {};
			const { document: signDocument } = signature ?? {};
			const { path: initialPath } = initialDocument ?? {};
			const { path: signPath } = signDocument ?? {};

			const payload: Partial<ICreateNewSignPayload> = {};
			payload.generateSignature = true;
			payload.envelopeId = envelopeId;
			payload.recipientId = recipient._id;
			if (signPath) {
				const getImage: string = await imagetToBase64(signPath);
				payload.signature = {
					base64File: formatBase64(getImage),
					name: `${recipient.fullName}-sign`,
					size: getSize(getImage),
					type: 'generate',
				};
			}
			if (initialPath) {
				const getImage: string = await imagetToBase64(initialPath);
				payload.initial = {
					base64File: formatBase64(getImage),
					name: `${recipient.fullName}-initial`,
					size: getSize(getImage),
					type: 'generate',
				};
			}
			const resp = await post(`${API_URL.SIGNATURE}`, payload);
			if (resp) {
				const { response, apiData } = resp;
				if (response?.status === 200) {
					return apiData?.data;
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userBioMetrics, recipient, imagetToBase64, getSize]
	);

	// this will get the upload id and submit the signed data to the server
	const submitDocumentSigning = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (envelopeId: string, payload: any) => {
		setIsLoaded(false);
	
		// Show "Signing in process" toast message when loading is false
		const toastId = !isLoaded ? toast('Signing in process', { autoClose: false }) : null;
	
		try {
		const resp = await patch(`${API_URL.ENVELOPE}/${envelopeId}`, payload);
			const { response, apiData } = resp;
			const isAlreadyCompleted = /Signer does not need to sign/.test(
				apiData?.message
			);
		if (response?.status === 200 || isAlreadyCompleted) {
			if (!webComponentData.recipientId) {
				const clinetRedirectUrl = searchParams.get('redirectUrl');
				if(clinetRedirectUrl){
					window.location.href = clinetRedirectUrl;
				}else{
					navigate(redirectUrl);
				}
			} else {
				setWebComponentData((prev) => ({
				...prev,
				signStatus: true,
				isWorkFlow: purpose === 'workflow',
			}));
			}
			setSigningFieldsFilled(true);
		} else {
			errorNotification(apiData?.message ?? API_ERROR_MESSAGE);
		}
		} catch (error) {
			errorNotification(API_ERROR_MESSAGE);
		} finally {
			setIsLoaded(true); 
			if (toastId) {
			toast.dismiss(toastId);
		}
		}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ isLoaded, webComponentData, redirectUrl, purpose, searchParams]
	);


	return {
		uploadSignedDocument,
		submitDocumentSigning,
		isLoaded,
		setIsLoaded,
		handleNewCreateSignId,
	};
};

export const useNodeIterator = () => {
	const signingNodes = useRecoilValue(CompiledSigningNodesState);
	const nodeRefs = useRecoilValue(nodeRefsState);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);

	const scrollToPage = (pageRef: HTMLElement) => {
		const container = document.getElementById('random-pdf-random-container');
		const windowHeight = 100;

		if (container) {
			const containerTopOffset = container.getBoundingClientRect().top;
			const nodeTopOffset = pageRef.getBoundingClientRect().top;

			const scrollOffset = windowHeight - pageRef.offsetHeight;
			let targetScrollTop =
				container.scrollTop +
				nodeTopOffset -
				containerTopOffset -
				Math.floor(scrollOffset / 2);

			const containerMaxScrollTop =
				container.scrollHeight - container.offsetHeight;
			targetScrollTop = Math.max(
				0,
				Math.min(targetScrollTop, containerMaxScrollTop)
			);

			container.scrollTo({
				top: targetScrollTop,
				left: pageRef.offsetLeft,
				behavior: 'smooth',
			});
		}
	};
	const scrollToNode = useCallback((nodeId: string) => {
		const node = document.getElementById(nodeId);
		if (node) {
			node.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);
	const nodeIterator = useCallback(
		(isNext = true, index?: number) => {
			const direction = isNext ? 1 : -1;
			const selectedNodeIndex = index ?? selectedNode.index;
			let nextButtonIndex: number =
				(Number(selectedNodeIndex) + direction + signingNodes.length) %
				signingNodes.length;
			let numTries = 0;

			// Check if the next/previous button is readonly, and skip over it if it is
			while (
				(signingNodes[nextButtonIndex]?.metadata.readonly ||
				signingNodes[nextButtonIndex]?.value !== '' ||
				signingNodes[nextButtonIndex]?.metadata?.required === false)
				&&
				signingNodes[nextButtonIndex]?.value !== null
			) {
				nextButtonIndex =
					(nextButtonIndex + direction + signingNodes.length) %
					signingNodes.length;
				numTries++;
				if (numTries >= signingNodes.length) {
					break;
				}
			}
			if (numTries >= signingNodes.length) {
				return; // exit the loop and stop navigation
			}

			setSelectedNode({
				index: nextButtonIndex,
				node: signingNodes[nextButtonIndex] || null,
			});

			if (signingNodes[nextButtonIndex]?._id) {
				scrollToNode(signingNodes[nextButtonIndex]?._id as string);
			} else {
				scrollToPage(nodeRefs.current[nextButtonIndex]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[nodeRefs, scrollToPage, selectedNode, signingNodes]
	);

	return { nodeIterator };
};
